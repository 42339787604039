/* eslint-disable camelcase */
import ApiService from 'api-client/ApiService'
import { GetServerPoolsResponse } from 'app/plugins/openstack/components/storage/pools/model'

class Cinder extends ApiService {
  public getClassName() {
    return 'cinder'
  }

  protected async getEndpoint() {
    return this.client.keystone.getServiceEndpoint('cinderv3', 'public')
  }

  volumesUrl = () => '/volumes'

  async getRegionUrls() {
    const services = (await this.client.keystone.getServiceCatalog()).find(
      (x) => x.name === 'cinderv3',
    ).endpoints
    const baseUrlsByRegion = services.reduce((accum, service) => {
      accum[service.region] = service.url
      return accum
    }, {})
    return baseUrlsByRegion
  }

  async getVolume(id) {
    const projectId = this.client.activeProjectId
    const response = await this.client.basicGet<any>({
      url: `/volumes/${id}`,
      options: {
        clsName: this.getClassName(),
        mthdName: 'getOpenstackVolume',
      },
    })
    return response.volume
  }

  // Get volumes with details
  async getVolumes() {
    const projectId = this.client.activeProjectId
    const response = await this.client.basicGet<any>({
      url: `/volumes/detail`,
      options: {
        clsName: this.getClassName(),
        mthdName: 'getOpenstackVolumes',
      },
    })
    return response.volumes
  }

  async getAllVolumes() {
    const response = await this.client.basicGet<any>({
      url: `/volumes/detail?all_tenants=1`,
      options: {
        clsName: this.getClassName(),
        mthdName: 'getAllVolumes',
      },
    })
    return response.volumes
  }

  async getAllVolumesCount(limit, allTenants, markerId) {
    const baseUrl = `${this.volumesUrl()}/detail`
    const limitUrl = `?limit=${limit}`
    const projectUrl = allTenants ? '&all_tenants=1' : ''
    const markerUrl = markerId ? `&marker=${markerId}` : ''
    const url = baseUrl + limitUrl + projectUrl + markerUrl
    const response = await this.client.basicGet<any>({
      url,
      options: {
        clsName: this.getClassName(),
        mthdName: 'getAllVolumesCount',
      },
    })
    return response.volumes
  }

  async createVolume(body) {
    const projectId = this.client.activeProjectId
    const response = await this.client.basicPost<any>({
      url: `/volumes`,
      body,
      options: {
        clsName: this.getClassName(),
        mthdName: 'createVolume',
      },
    })
    return response.volume
  }

  async deleteVolume(id) {
    const projectId = this.client.activeProjectId
    return this.client.basicDelete<any>({
      url: `/volumes/${id}`,
      options: {
        clsName: this.getClassName(),
        mthdName: 'deleteVolume',
      },
    })
  }

  async updateVolume(id, params) {
    const response = await this.client.basicPut<any>({
      url: `${this.volumesUrl()}/${id}`,
      body: {
        volume: params,
      },
      options: {
        clsName: this.getClassName(),
        mthdName: 'updateVolume',
      },
    })
    return response.volume
  }

  async setBootable(id, bool) {
    const response = await this.client.basicPost<any>({
      url: `${this.volumesUrl()}/${id}/action`,
      body: {
        'os-set_bootable': { bootable: bool },
      },
      options: {
        clsName: this.getClassName(),
        mthdName: 'setBootable',
      },
    })
    return response.volume
  }

  // TODO: Test case for extend function
  // TODO: Current API doesn't work on AWS. Need to implement check logic in test.
  async extendVolume(id, size) {
    const response = await this.client.basicPost<any>({
      url: `${this.volumesUrl()}/${id}/action`,
      body: {
        'os-extend': { new_size: size },
      },
      options: {
        clsName: this.getClassName(),
        mthdName: 'extendVolume',
      },
    })
    return response
  }

  // TODO: test case for reset function (Instance implement needed. Attach function needed?)
  async resetVolumeStatus(id) {
    const response = await this.client.basicPost<any>({
      url: `${this.volumesUrl()}/${id}/action`,
      body: {
        'os-reset_status': {
          status: 'available',
          attach_status: 'detached',
        },
      },
      options: {
        clsName: this.getClassName(),
        mthdName: 'resetVolumeStatus',
      },
    })
    return response.volume
  }

  async uploadVolumeAsImage({ id, body }) {
    const response = await this.client.basicPost<any>({
      url: `/volumes/${id}/action`,
      body,
      options: {
        clsName: this.getClassName(),
        mthdName: `uploadVolumeAsImage`,
      },
    })
    return response
  }

  async getVolumeTypes() {
    const projectId = this.client.activeProjectId
    const response = await this.client.basicGet<any>({
      url: `/types`,
      options: {
        clsName: this.getClassName(),
        mthdName: 'getVolumeTypes',
      },
    })
    return response.volume_types
  }

  async getVolumeType(id) {
    const projectId = this.client.activeProjectId
    const response = await this.client.basicGet<any>({
      url: `/types/${id}`,
      options: {
        clsName: this.getClassName(),
        mthdName: 'getVolumeType',
      },
    })
    return response.volume_type
  }

  async createVolumeType(body) {
    const response = await this.client.basicPost<any>({
      url: `/types`,
      body,
      options: {
        clsName: this.getClassName(),
        mthdName: 'createVolumeType',
      },
    })
    return response.volume_type
  }

  async deleteVolumeType(id) {
    await this.client.basicDelete<any>({
      url: `/types/${id}`,
      options: {
        clsName: this.getClassName(),
        mthdName: 'deleteVolumeType',
      },
    })
  }

  async updateVolumeType(id, params, keysToDelete = []) {
    const { extra_specs: extraSpecs } = params
    await this.client.basicPost<any>({
      url: `/types/${id}/extra_specs`,
      body: {
        extra_specs: extraSpecs,
      },
      options: {
        clsName: this.getClassName(),
        mthdName: 'updateVolumeType/extra_specs',
      },
    })
    await Promise.all(
      keysToDelete.map(async (key) => {
        return this.client.basicDelete<any>({
          url: `/types/${id}/extra_specs/${key}`,
          options: {
            clsName: this.getClassName(),
            mthdName: `updateVolumeType/delete/${key}`,
          },
        })
      }),
    )
    // const baseResponse = await this.client.basicPut<any>({
    //   url: `/types/${id}`,
    //   body: {
    //     volume_type: rest,
    //   },
    //   options: {
    //     clsName: this.getClassName(),
    //     mthdName: 'updateVolumeType',
    //   },
    // })
    // return baseResponse
  }

  async unsetVolumeTypeTag(id, tag) {
    await this.client.basicDelete<any>({
      url: `/types/${id}/extra_specs/${tag}`,
      options: {
        clsName: this.getClassName(),
        mthdName: `unsetVolumeTypeTag`,
      },
    })
  }

  async getVolumeSnapshot(id) {
    const projectId = this.client.activeProjectId
    const response = await this.client.basicGet<any>({
      url: `/snapshots/${id}`,
      options: {
        clsName: this.getClassName(),
        mthdName: 'getOpenstackVolumeSnapshot',
      },
    })
    return response.snapshot
  }

  async getVolumeSnapshots() {
    const projectId = this.client.activeProjectId
    const response = await this.client.basicGet<any>({
      url: `/snapshots/detail`,
      options: {
        clsName: this.getClassName(),
        mthdName: `getOpenstackVolumeSnapshots`,
      },
    })
    return response.snapshots
  }

  // async getAllSnapshots() {
  //   const response = await this.client.basicGet<any>({
  //     url: `/snapshots/detail?all_tenants=1`,
  //     options: {
  //       clsName: this.getClassName(),
  //       mthdName: `getAllSnapshots`,
  //     },
  //   })
  //   return response.snapshots
  // }

  async snapshotVolume(body) {
    const projectId = this.client.activeProjectId
    const response = await this.client.basicPost<any>({
      url: `/snapshots`,
      body,
      options: {
        clsName: this.getClassName(),
        mthdName: `snapshotVolume`,
      },
    })
    return response.snapshot
  }

  // async deleteSnapshot(id) {
  //   await this.client.basicDelete<any>({
  //     url: `/snapshots/${id}`,
  //     options: {
  //       clsName: this.getClassName(),
  //       mthdName: `deleteSnapshot`,
  //     },
  //   })
  // }

  async deleteVolumeSnapshot(id) {
    const projectId = this.client.activeProjectId
    return this.client.basicDelete<any>({
      url: `/snapshots/${id}`,
      options: {
        clsName: this.getClassName(),
        mthdName: 'deleteVolumeSnapshot',
      },
    })
  }

  async updateSnapshot(id, params) {
    const response = await this.client.basicPut<any>({
      url: `/snapshots/${id}`,
      body: {
        snapshot: params,
      },
      options: {
        clsName: this.getClassName(),
        mthdName: `updateSnapshot`,
      },
    })
    return response.snapshot
  }

  async updateSnapshotMetadata(id, params) {
    const response = await this.client.basicPut<any>({
      url: `/snapshots/${id}/metadata`,
      body: {
        metadata: params,
      },
      options: {
        clsName: this.getClassName(),
        mthdName: `updateSnapshotMetadata`,
      },
    })
    return response.metadata
  }

  async getDefaultQuotas() {
    const quotas = await this.client.basicGet<any>({
      url: `/os-quota-class-sets/defaults`,

      options: {
        clsName: this.getClassName(),
        mthdName: `getDefaultQuotas`,
      },
    })
    return quotas.quota_class_set
  }

  async getDefaultQuotasForRegion(region) {
    const urls = await this.getRegionUrls()
    const quotas = await this.client.basicGet<any>({
      endpoint: urls[region],
      url: `/os-quota-class-sets/defaults`,
      options: {
        clsName: this.getClassName(),
        mthdName: `getDefaultQuotasForRegion`,
      },
    })
    return quotas.quota_class_set
  }

  getTenantQuotas = async ({ id }) => {
    const response = await this.client.basicGet<any>({
      url: `/os-quota-sets/${id}`,
      options: {
        clsName: this.getClassName(),
        mthdName: 'getCinderQuotas',
      },
    })
    return response.quota_set
  }

  getTenantQuotaUsage = async ({ id }) => {
    const response = await this.client.basicGet<any>({
      url: `/os-quota-sets/${id}?usage=true`,
      options: {
        clsName: this.getClassName(),
        mthdName: 'getCinderQuotaUsage',
      },
    })
    return response.quota_set
  }

  setTenantQuotas = async ({ id, body }) => {
    const quotas = await this.client.basicPut<any>({
      url: `/os-quota-sets/${id}`,
      body,
      options: {
        clsName: this.getClassName(),
        mthdName: `setCinderQuotas`,
      },
    })
    return quotas.quota_set
  }

  async getQuotas(projectId) {
    const quota = await this.client.basicGet<any>({
      endpoint: `/os-quota-sets?usage=true`,
      url: `/os-quota-class-sets/defaults`,
      options: {
        clsName: this.getClassName(),
        mthdName: `getQuotas`,
      },
    })
    return quota.quota_set
  }

  async getQuotasForRegion(projectId, region) {
    const urls = await this.getRegionUrls()
    const quota = await this.client.basicGet<any>({
      endpoint: urls[region],
      url: `/os-quota-sets?usage=true`,
      options: {
        clsName: this.getClassName(),
        mthdName: `getQuotasForRegion`,
      },
    })
    return quota.quota_set
  }

  async setQuotas(params, projectId) {
    const quotas = await this.client.basicPut<any>({
      url: `/os-quota-sets`,
      body: {
        quota_set: params,
      },
      options: {
        clsName: this.getClassName(),
        mthdName: `setQuotas`,
      },
    })
    return quotas.quota_set
  }

  async setQuotasForRegion(params, projectId, region) {
    const urls = await this.getRegionUrls()
    const quotas = await this.client.basicPut<any>({
      endpoint: urls[region],
      url: `/os-quota-sets`,
      body: {
        quota_set: params,
      },
      options: {
        clsName: this.getClassName(),
        mthdName: `setQuotasForRegion`,
      },
    })
    return quotas.quota_set
  }

  async getStoragePools() {
    const response = await this.client.basicGet<GetServerPoolsResponse>({
      url: `/scheduler-stats/get_pools?detail=True`,
      options: {
        clsName: this.getClassName(),
        mthdName: 'getStoragePools',
      },
    })
    return response.pools
  }

  // TODO: getStorageStats(need to implement host first)
}

export default Cinder
