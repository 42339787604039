import ApiService from 'api-client/ApiService'
import config from 'app-config'
import { cleanResource } from 'app/plugins/infrastructure/components/clusters/aws/capi/capiUtils'
import store from 'app/store'
import { sessionStoreKey } from 'core/session/sessionReducers'
import qs from 'qs'
import { trackApiMethodMetadata } from './helpers'
import { DexAuthToken } from './keystone.model'

class Kaapi extends ApiService {
  public getClassName() {
    return 'kaapi'
  }

  static apiMethodsMetadata = []

  protected async getEndpoint() {
    return Promise.resolve(config.apiHost)
  }

  get baseUrl() {
    const state = store.getState()
    const { activeTenant } = state[sessionStoreKey]
    return `/oidc-proxy/${activeTenant}`
  }

  get token() {
    const state = store.getState()
    const { jwtToken } = state[sessionStoreKey]
    return jwtToken
  }

  get kaapiNamespace() {
    const state = store.getState()
    const { activeKaapiTenant } = state[sessionStoreKey]
    return activeKaapiTenant
  }

  @trackApiMethodMetadata({
    url: '/apis/infrastructure.cluster.x-k8s.io/v1beta1/namespaces/:namespace/openstackclusters',
    type: 'POST',
    params: ['namespace'],
    error: { isClusterError: true, k8sResource: 'OpenStackCluster' },
  })
  createOpenStackCluster = async (namespace, body) => {
    const url = `${this.baseUrl}/apis/infrastructure.cluster.x-k8s.io/v1beta1/namespaces/${this.kaapiNamespace}/openstackclusters`
    const data = await this.client.basicPost<any>({
      url,
      body,
      options: {
        clsName: this.getClassName(),
        mthdName: 'createOpenStackCluster',
        config: {
          headers: {
            Authorization: `Bearer ${this.token}`,
          },
        },
      },
    })
    return cleanResource()(data)
  }

  @trackApiMethodMetadata({
    url: '/apis/infrastructure.cluster.x-k8s.io/v1beta1/namespaces/:namespace/openstackclusters',
    type: 'GET',
    params: ['namespace'],
    error: { isClusterError: true, k8sResource: 'OpenStackClusters' },
  })
  getOpenStackClusters = async ({ namespace }) => {
    const url = `${this.baseUrl}/apis/infrastructure.cluster.x-k8s.io/v1beta1/namespaces/${this.kaapiNamespace}/openstackclusters`
    const data = await this.client.basicGet<any>({
      url,
      options: {
        clsName: this.getClassName(),
        mthdName: 'getOpenStackClusters',
        config: {
          headers: {
            Authorization: `Bearer ${this.token}`,
          },
        },
      },
    })
    return cleanResource()(data.items)
  }

  @trackApiMethodMetadata({
    url: '/apis/cluster.x-k8s.io/v1beta1/namespaces/:namespace/openstackclusters',
    type: 'POST',
    params: ['namespace'],
    error: { isClusterError: true, k8sResource: 'Cluster' },
  })
  createCluster = async (namespace, body) => {
    const url = `${this.baseUrl}/apis/cluster.x-k8s.io/v1beta1/namespaces/${this.kaapiNamespace}/clusters`
    const data = await this.client.basicPost<any>({
      url,
      body,
      options: {
        clsName: this.getClassName(),
        mthdName: 'createCluster',
        config: {
          headers: {
            Authorization: `Bearer ${this.token}`,
          },
        },
      },
    })
    return cleanResource()(data)
  }

  @trackApiMethodMetadata({
    url: '/apis/cluster.x-k8s.io/v1beta1/namespaces/:namespace/clusters',
    type: 'GET',
    params: ['namespace'],
    error: { isClusterError: true, k8sResource: 'Clusters' },
  })
  getKaapiClusters = async ({ namespace }) => {
    const url = `${this.baseUrl}/apis/cluster.x-k8s.io/v1beta1/namespaces/${this.kaapiNamespace}/clusters`
    const data = await this.client.basicGet<any>({
      url,
      options: {
        clsName: this.getClassName(),
        mthdName: 'getKaapiClusters',
        config: {
          headers: {
            Authorization: `Bearer ${this.token}`,
          },
        },
      },
    })
    return cleanResource()(data.items)
  }

  @trackApiMethodMetadata({
    url: '/apis/cluster.x-k8s.io/v1beta1/namespaces/:namespace/clusters',
    type: 'DELETE',
    params: ['namespace', 'name'],
    error: { isClusterError: true, k8sResource: 'Clusters' },
  })
  deleteKaapiCluster = async ({ namespace, name }) => {
    const url = `${this.baseUrl}/apis/cluster.x-k8s.io/v1beta1/namespaces/${this.kaapiNamespace}/clusters/${name}`
    const data = await this.client.basicDelete<any>({
      url,
      options: {
        clsName: this.getClassName(),
        mthdName: 'deleteKaapiCluster',
        config: {
          headers: {
            Authorization: `Bearer ${this.token}`,
          },
        },
      },
    })
    return cleanResource()(data)
  }

  @trackApiMethodMetadata({
    url: '/apis/controlplane.platform9.io/v1alpha1/namespaces/:namespace/hostedcontrolplanes',
    type: 'POST',
    params: ['namespace'],
    error: { isClusterError: true, k8sResource: 'HostedControlPlane' },
  })
  createHostedControlPlane = async (namespace, body) => {
    const url = `${this.baseUrl}/apis/controlplane.platform9.io/v1alpha1/namespaces/${this.kaapiNamespace}/hostedcontrolplanes`
    const data = await this.client.basicPost<any>({
      url,
      body,
      options: {
        clsName: this.getClassName(),
        mthdName: 'createHostedControlPlane',
        config: {
          headers: {
            Authorization: `Bearer ${this.token}`,
          },
        },
      },
    })
    return cleanResource()(data)
  }

  @trackApiMethodMetadata({
    url: '/apis/controlplane.platform9.io/v1alpha1/namespaces/:namespace/hostedcontrolplanes',
    type: 'GET',
    params: ['namespace'],
    error: { isClusterError: true, k8sResource: 'HostedControlPlane' },
  })
  getHostedControlPlanes = async ({ namespace }) => {
    const url = `${this.baseUrl}/apis/controlplane.platform9.io/v1alpha1/namespaces/${this.kaapiNamespace}/hostedcontrolplanes`
    const data = await this.client.basicGet<any>({
      url,
      options: {
        clsName: this.getClassName(),
        mthdName: 'getHostedControlPlanes',
        config: {
          headers: {
            Authorization: `Bearer ${this.token}`,
          },
        },
      },
    })
    return cleanResource()(data.items)
  }

  @trackApiMethodMetadata({
    url: '/apis/cluster.x-k8s.io/v1beta1/namespaces/:namespace/machinedeployments',
    type: 'POST',
    params: ['namespace'],
    error: { isClusterError: true, k8sResource: 'MachineDeployment' },
  })
  createMachineDeployment = async (namespace, body) => {
    const url = `${this.baseUrl}/apis/cluster.x-k8s.io/v1beta1/namespaces/${this.kaapiNamespace}/machinedeployments`
    const data = await this.client.basicPost<any>({
      url,
      body,
      options: {
        clsName: this.getClassName(),
        mthdName: 'createMachineDeployment',
        config: {
          headers: {
            Authorization: `Bearer ${this.token}`,
          },
        },
      },
    })
    return cleanResource()(data)
  }

  @trackApiMethodMetadata({
    url: '/apis/cluster.x-k8s.io/v1beta1/namespaces/:namespace/machinedeployments',
    type: 'DELETE',
    params: ['namespace'],
    error: { isClusterError: true, k8sResource: 'MachineDeployment' },
  })
  deleteKaapiMachineDeployments = async ({ namespace, name }) => {
    const url = `${this.baseUrl}/apis/cluster.x-k8s.io/v1beta1/namespaces/${this.kaapiNamespace}/machinedeployments/${name}`
    const data = await this.client.basicDelete<any>({
      url,
      options: {
        clsName: this.getClassName(),
        mthdName: 'deleteMachineDeployment',
        config: {
          headers: {
            Authorization: `Bearer ${this.token}`,
          },
        },
      },
    })
    return cleanResource()(data)
  }

  @trackApiMethodMetadata({
    url: '/apis/cluster.x-k8s.io/v1beta1/namespaces/:namespace/machinedeployments',
    type: 'GET',
    params: ['namespace'],
    error: { isClusterError: true, k8sResource: 'MachineDeployment' },
  })
  getMachineDeployments = async ({ namespace }) => {
    const url = `${this.baseUrl}/apis/cluster.x-k8s.io/v1beta1/namespaces/${this.kaapiNamespace}/machinedeployments`
    const data = await this.client.basicGet<any>({
      url,
      options: {
        clsName: this.getClassName(),
        mthdName: 'listMachineDeployments',
        config: {
          headers: {
            Authorization: `Bearer ${this.token}`,
          },
        },
      },
    })
    return cleanResource()(data.items)
  }

  @trackApiMethodMetadata({
    url: '/apis/cluster.x-k8s.io/v1beta1/namespaces/:namespace/Machines',
    type: 'GET',
    params: ['namespace'],
    error: { isClusterError: true, k8sResource: 'Machines' },
  })
  getMachines = async ({ namespace }) => {
    const url = `${this.baseUrl}/apis/cluster.x-k8s.io/v1beta1/namespaces/${this.kaapiNamespace}/machines`
    const data = await this.client.basicGet<any>({
      url,
      options: {
        clsName: this.getClassName(),
        mthdName: 'getMachines',
        config: {
          headers: {
            Authorization: `Bearer ${this.token}`,
          },
        },
      },
    })
    return cleanResource()(data.items)
  }

  @trackApiMethodMetadata({
    url: '/apis/cluster.x-k8s.io/v1beta1/namespaces/:namespace/machinedeployments',
    type: 'DELETE',
    params: ['namespace'],
    error: { isClusterError: true, k8sResource: 'Machines' },
  })
  deleteKaapiMachines = async ({ namespace, name }) => {
    const url = `${this.baseUrl}/apis/cluster.x-k8s.io/v1beta1/namespaces/${this.kaapiNamespace}/machines/${name}`
    const data = await this.client.basicDelete<any>({
      url,
      options: {
        clsName: this.getClassName(),
        mthdName: 'deleteMachines',
        config: {
          headers: {
            Authorization: `Bearer ${this.token}`,
          },
        },
      },
    })
    return cleanResource()(data)
  }

  @trackApiMethodMetadata({
    url:
      '/apis/infrastructure.cluster.x-k8s.io/v1beta1/namespaces/:namespace/openstackmachinetemplates',
    type: 'POST',
    params: ['namespace'],
    error: { isClusterError: true, k8sResource: 'OpenStackMachineTemplate' },
  })
  createOpenStackMachineTemplate = async (namespace, body) => {
    const url = `${this.baseUrl}/apis/infrastructure.cluster.x-k8s.io/v1beta1/namespaces/${this.kaapiNamespace}/openstackmachinetemplates`
    const data = await this.client.basicPost<any>({
      url,
      body,
      options: {
        clsName: this.getClassName(),
        mthdName: 'createOpenStackMachineTemplate',
        config: {
          headers: {
            Authorization: `Bearer ${this.token}`,
          },
        },
      },
    })
    return cleanResource()(data)
  }

  @trackApiMethodMetadata({
    url:
      '/apis/infrastructure.cluster.x-k8s.io/v1beta1/namespaces/:namespace/openstackmachinetemplates',
    type: 'GET',
    params: ['namespace'],
    error: { isClusterError: true, k8sResource: 'OpenStackMachineTemplate' },
  })
  getOpenStackMachineTemplates = async ({ namespace }) => {
    const url = `${this.baseUrl}/apis/infrastructure.cluster.x-k8s.io/v1beta1/namespaces/${this.kaapiNamespace}/openstackmachinetemplates`
    const data = await this.client.basicGet<any>({
      url,
      options: {
        clsName: this.getClassName(),
        mthdName: 'getOpenStackMachineTemplates',
        config: {
          headers: {
            Authorization: `Bearer ${this.token}`,
          },
        },
      },
    })
    return cleanResource()(data.items)
  }

  @trackApiMethodMetadata({
    url: '/apis/bootstrap.cluster.x-k8s.io/v1beta1/namespaces/:namespace/kubeadmconfigtemplates',
    type: 'POST',
    params: ['namespace'],
    error: { isClusterError: true, k8sResource: 'KubeadmConfigTemplate' },
  })
  createKubeadmConfigTemplate = async (namespace, body) => {
    const url = `${this.baseUrl}/apis/bootstrap.cluster.x-k8s.io/v1beta1/namespaces/${this.kaapiNamespace}/kubeadmconfigtemplates`
    const data = await this.client.basicPost<any>({
      url,
      body,
      options: {
        clsName: this.getClassName(),
        mthdName: 'createKubeadmConfigTemplate',
        config: {
          headers: {
            Authorization: `Bearer ${this.token}`,
          },
        },
      },
    })
    return cleanResource()(data)
  }

  @trackApiMethodMetadata({
    url: '/apis/bootstrap.cluster.x-k8s.io/v1beta1/namespaces/:namespace/kubeadmconfigtemplates',
    type: 'GET',
    params: ['namespace'],
    error: { isClusterError: true, k8sResource: 'KubeadmConfigTemplate' },
  })
  getKubeadmConfigTemplate = async ({ namespace }) => {
    const url = `${this.baseUrl}/apis/bootstrap.cluster.x-k8s.io/v1beta1/namespaces/${this.kaapiNamespace}/kubeadmconfigtemplates`
    const data = await this.client.basicGet<any>({
      url,
      options: {
        clsName: this.getClassName(),
        mthdName: 'getKubeadmConfigTemplate',
        config: {
          headers: {
            Authorization: `Bearer ${this.token}`,
          },
        },
      },
    })
    return cleanResource()(data.items)
  }

  @trackApiMethodMetadata({
    url: '/apis/core.barista.platform9.io/v1alpha1/namespaces/:namespace/clusteraddons',
    type: 'POST',
    params: ['namespace'],
    error: { isClusterError: true, k8sResource: 'ClusterAddon' },
  })
  createClusterAddon = async (namespace, body) => {
    const url = `${this.baseUrl}/apis/core.barista.platform9.io/v1alpha1/namespaces/${this.kaapiNamespace}/clusteraddons`
    const data = await this.client.basicPost<any>({
      url,
      body,
      options: {
        clsName: this.getClassName(),
        mthdName: 'createClusterAddon',
        config: {
          headers: {
            Authorization: `Bearer ${this.token}`,
          },
        },
      },
    })
    return cleanResource()(data)
  }

  @trackApiMethodMetadata({
    url: '/apis/core.barista.platform9.io/v1alpha1/namespaces/:namespace/clusteraddons',
    type: 'GET',
    params: ['namespace'],
    error: { isClusterError: true, k8sResource: 'ClusterAddon' },
  })
  getClusterAddons = async ({ namespace }) => {
    const url = `${this.baseUrl}/apis/core.barista.platform9.io/v1alpha1/namespaces/${this.kaapiNamespace}/clusteraddons`
    const data = await this.client.basicGet<any>({
      url,
      options: {
        clsName: this.getClassName(),
        mthdName: 'getClusterAddons',
        config: {
          headers: {
            Authorization: `Bearer ${this.token}`,
          },
        },
      },
    })
    return cleanResource()(data)
  }

  // Tenant Resources
  @trackApiMethodMetadata({
    url: '/apis/core.barista.platform9.io/v1alpha1/tenants',
    type: 'GET',
    params: ['namespace'],
    error: { isClusterError: true, k8sResource: 'Tenant' },
  })
  getAllTenants = async () => {
    const url = `${this.baseUrl}/apis/core.barista.platform9.io/v1alpha1/tenants`
    try {
      const data = await this.client.basicGet<any>({
        url,
        options: {
          clsName: this.getClassName(),
          mthdName: 'getTanants',
          config: {
            headers: {
              Authorization: `Bearer ${this.token}`,
            },
          },
        },
      })
      return cleanResource()(data)
    } catch (e) {
      console.log(e)
      return null
    }
  }

  @trackApiMethodMetadata({
    url: '/apis/core.barista.platform9.io/v1alpha1/tenants',
    type: 'GET',
    params: ['namespace'],
    error: { isClusterError: true, k8sResource: 'Tenant' },
  })
  getKaapiTenant = async ({ tenantName }) => {
    const url = `${this.baseUrl}/apis/core.barista.platform9.io/v1alpha1/tenants/${tenantName}`
    try {
      const data = await this.client.basicGet<any>({
        endpoint: config.kaapiURL,
        url,
        options: {
          clsName: this.getClassName(),
          mthdName: 'getTanants',
          config: {
            headers: {
              Authorization: `Bearer ${this.token}`,
            },
          },
        },
      })
      return cleanResource()(data)
    } catch (e) {
      console.log(e)
      return null
    }
  }

  @trackApiMethodMetadata({
    url: '/apis/core.barista.platform9.io/v1alpha1/tenants',
    type: 'POST',
    params: ['namespace'],
    error: { isClusterError: true, k8sResource: 'Tenant' },
  })
  createTenant = async ({ body }) => {
    const url = `${this.baseUrl}/apis/core.barista.platform9.io/v1alpha1/tenants`
    try {
      const data = await this.client.basicPost<any>({
        endpoint: config.kaapiURL,
        url,
        body,
        options: {
          clsName: this.getClassName(),
          mthdName: 'createTenant',
          config: {
            headers: {
              Authorization: `Bearer ${this.token}`,
            },
          },
        },
      })
      return cleanResource()(data)
    } catch (e) {
      console.log(e)
      return null
    }
  }

  // This is to authenticate the user with the dex server and get the JWT token
  peformDexLogin = async ({
    username,
    password,
    domain = 'default',
  }): Promise<DexAuthToken | null> => {
    // Get the dex service endpoint
    // const url = await this.getServiceEndpoint('dex', 'public')
    try {
      const response = await this.client.rawPost<DexAuthToken>({
        url: '/dex/token',
        data: qs.stringify({
          grant_type: 'password',
          client_id: 'kubernetes',
          client_secret: 'BTXO4K60xxQHBvwQ',
          scope: 'openid offline_access groups email',
          username,
          password,
          connector_id: domain,
        }),
        config: {
          headers: {
            'Content-Type': 'application/x-www-form-urlencoded',
          },
        },
        options: {
          clsName: this.getClassName(),
          mthdName: 'performDexLoin',
        },
      })
      return response.data
    } catch (err) {
      console.log(err)
      return null
    }
  }

  downloadKubeconfig = async ({ clusterName }) => {
    try {
      const response = await this.client.basicGet<any>({
        url: `${this.baseUrl}/api/v1/namespaces/${this.kaapiNamespace}/secrets/${clusterName}cp-admin-kubeconfig`,
        options: {
          clsName: this.getClassName(),
          mthdName: 'downloadKubeconfig',
          config: {
            headers: {
              Authorization: `Bearer ${this.token}`,
            },
          },
        },
      })

      console.log('response', response)
      console.log('super-admin.conf', response['super-admin.conf'])
      return response?.['super-admin.conf']
    } catch (e) {
      console.log(e)
      return null
    }
  }

  @trackApiMethodMetadata({
    url: '/k8sapi/api/v1/namespaces/:namespace/configmaps`/',
    type: 'POST',
    params: ['namespace'],
    error: { isClusterError: true, k8sResource: 'ConfigMaps' },
  })
  getConfigMaps = async ({ namespace }) => {
    const url = `${this.baseUrl}/api/v1/namespaces/${this.kaapiNamespace}/configmaps`
    const data = await this.client.basicGet<any>({
      url,
      options: {
        clsName: this.getClassName(),
        mthdName: 'getConfigMaps',
        config: {
          headers: {
            Authorization: `Bearer ${this.token}`,
          },
        },
      },
    })
    return cleanResource()(data.items)
  }
}

export default Kaapi
